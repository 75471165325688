@import "../../assets/scss/variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Sofia Pro Light Az", "sans-serif";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  text-align: right;
  color: $maastright-blue;
  border-bottom: 1px solid $white-edgard;
  padding: 16px 37px;
  column-gap: 10%;
  z-index: 3;
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;

  a {
    color: $maastright-blue;
  }

  ul {
    display: flex;
    li:first-child {
      font-weight: 700;
    }

    a {
      cursor: pointer;
    }
  }
  .nav-wrapp {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    .nav-auth-list {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 25px;
      width: 100%;
      color: $white;
      padding: 50px;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        width: 100%;
        .link-box {
          display: flex;
          align-items: center;
          column-gap: 20px;
          flex-grow: 1;
          .left-icon-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: $white;
              opacity: 0.1;
            }

            i {
              font-size: 25px;
              position: relative;
              z-index: 1;
              opacity: 1;
            }
          }
          a {
            color: $white;
            font-weight: 300;
            font-size: 16px;
            line-height: 100%;
            flex-grow: 1;
            text-align: left;
          }
        }
        .right-icon-box {
          display: flex;
          align-items: center;
          .amount-box {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            min-width: 20px;
            border-radius: 10px;
            background-color: $red;
            font-weight: bold;
            font-size: 11px;
            line-height: 11px;
            padding: 0 3px;
            span {
              max-width: 6ch;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      @media (max-width: 991px) {
        display: flex;
      }
    }
    @media (max-width: 991px) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      max-width: 375px;
      flex-direction: column-reverse;
      background-color: $lucidity;
      z-index: 2;
      transform: translateX(-100%);
      &.opened {
        transition: transform 0.3s ease-out;
        transform: translateX(0);
      }
    }
  }
  nav {
    flex: 0 1 80%;
    @media (max-width: 991px) {
      max-width: none;
      width: 100%;
      padding: 50px;
      background-color: $effervescent-blue;
    }
    ul {
      flex: 1 1 100%;
      flex-wrap: wrap;
      column-gap: 30px;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 25px;
      }
      li {
        position: relative;
        &.active::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -34px;
          width: 100%;
          display: inline-block;
          height: 3px;
          background-color: $orange-yellow;
        }
        @media (max-width: 991px) {
          margin-right: 0;
          line-height: 100%;
          &.active::after {
            content: none;
          }
        }

        a {
          display: block;
          position: relative;
          @media (max-width: 991px) {
            color: $white;
          }
          &.active:before {
            display: block;
            content: "";
            height: 1px;
            width: 30px;
            bottom: -27px;
            position: absolute;
            width: 100%;
            background-color: $orange-yellow;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    padding: 17px 20px 19px;
    .logo {
      img {
        width: 88px;
        height: 30px;
      }
    }
  }
}

.logo {
  cursor: pointer;
  position: relative;
}

.iconSize {
  width: 45px;
  height: 45px;
  border: 1px solid $black;
  box-sizing: border-box;
  border-radius: 50px;
  margin-left: 20px;
  position: relative;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.userAvatar {
  overflow: hidden;

  i {
    font-size: 22px;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &::after {
    position: absolute;
    content: "";
    height: inherit;
    width: inherit;
    background-color: $orange-yellow;
    border-radius: inherit;
    z-index: -1;
    left: 1px;
    top: 1px;
  }
  @media (max-width: 991px) {
    margin-right: 20px;
    margin-left: 0;
    background-color: $orange-yellow;
    border-color: $orange-yellow;
    i {
      color: $white;
    }
    &::after {
      background-color: transparent;
      border: 1px solid $white;
    }
  }
}

.userPart {
  display: flex;
  align-items: center;
  .close {
    display: none;
    flex-grow: 1;
    padding-left: 10px;
    .mobile-close-box {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid $white;
      span {
        position: relative;
        width: 15px;
        height: 1.5px;
        background-color: $white;
        border-radius: 1000px;
        transform: rotate(45deg);
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 15px;
          height: 1.5px;
          background-color: $white;
          border-radius: 1000px;
          transform: rotate(90deg);
        }
      }
    }
    @media (max-width: 991px) {
      display: block;
    }
  }
  .separator {
    margin: 0 5px;
  }

  @media (max-width: 991px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 100%;
    padding: 20px 20px 0;
    .separator {
      color: $white;
    }
    a {
      color: $white;
    }
  }
}

.mobile-box {
  display: none !important;
  align-items: center;
  flex-basis: 100px;
  @media (max-width: 991px) {
    display: flex !important;
  }
  .burger {
    &::after {
      position: absolute;
      content: "";
      height: inherit;
      width: inherit;
      background-color: $orange-yellow;
      border-radius: inherit;
      z-index: -1;
      left: 1px;
      top: 1px;
    }
    span {
      position: absolute;
      left: calc(50% - 7.5px);
      top: 50%;
      width: 15px;
      height: 1.5px;
      background-color: $black;
      border-radius: 1000px;
      transform: translateY(-50%);
      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -5px;
        width: 15px;
        height: 1.5px;
        background-color: $black;
        border-radius: 1000px;
      }
      &::after {
        top: auto;
        bottom: -5px;
      }
    }
  }
}
.overlay {
  position: fixed;
  inset: 0;
  user-select: none;
  background-color: $black;
  opacity: 0;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  transition: all 0.2s ease-in-out;
  &.visible {
    user-select: all;
    visibility: visible;
    opacity: 0.6;
  }
}

.popover {
  z-index: 8;
  margin-top: 21px;
  width: 100vw;
}
.filter-button {
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 18px;
  color: $black;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.popover-filters {
  z-index: 9;
  margin-top: 10px;
  border: 1px solid #000;
  border-radius: 12px;
  padding: 32px 42px 32px 42px;
  background-color: #fff;
}